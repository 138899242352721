.awesomplete [hidden] {
    display: none;
}

.awesomplete .visually-hidden {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    display: none;
}

.awesomplete {
    display: inline-block;
    position: relative;
    width: 100%;
}

.awesomplete > input {
    display: block;
}

.awesomplete > ul {
    position: absolute;
    left: 0;
    z-index: 1;
    min-width: 100%;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
}

.awesomplete > ul:empty {
    display: none;
}

.awesomplete > ul {
	/*border-radius: .3em;*/
	/*margin: .2em 0 0;*/
	background: hsla(0,0%,100%,.9);
	background: linear-gradient(to bottom right, white, hsla(0,0%,100%,.8));
	/*border: 1px solid rgba(0,0,0,.3);*/
	box-shadow: 0.05em 0.2em 3em rgba(0,0,0,.2);
	text-shadow: none;
  text-align: left;
}

@supports (transform: scale(0)) {
	.awesomplete > ul {
		transition: .3s cubic-bezier(.4,.2,.5,1.4);
		transform-origin: 1.43em -.43em;
	}

	.awesomplete > ul[hidden],
	.awesomplete > ul:empty {
		opacity: 0;
		transform: scaleY(0);
		display: block;
		transition-timing-function: ease;
	}
}

	/* Pointer */
/*	.awesomplete > ul:before {
		content: "";
		position: absolute;
		top: -.43em;
		left: 1em;
		width: 0; height: 0;
		padding: .4em;
		background: white;
		border: inherit;
		border-right: 0;
		border-bottom: 0;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}*/

	.awesomplete > ul > li {
		position: relative;
		padding: .375em .5em;
		cursor: pointer;
	}

	.awesomplete > ul > li:hover {
		background: var(--orange);
		color: white;
	}

	.awesomplete > ul > li[aria-selected="true"] {
		background: var(--orange);
		color: white;
	}

		.awesomplete mark {
			background: transparent;
		}

		.awesomplete li:hover mark {
			background: var(--orange);
      color: white;
		}

		.awesomplete li[aria-selected="true"] mark {
			background: var(--orange);
			color: white;
		}
/*# sourceMappingURL=awesomplete.css.map */
