/* -------------------------------------------------------------------------------- */
/*  =Imports
/* -------------------------------------------------------------------------------- */

/* BassCSS */
@import 'normalize.css';
@import 'basscss-btn';
@import 'basscss-btn-outline';
@import 'basscss-btn-primary';
@import 'basscss-btn-sizes';
@import 'basscss-background-images';
@import 'basscss-background-colors';
@import 'basscss-colors';
@import 'basscss';
@import 'basscss-responsive-layout';
@import 'basscss-responsive-margin';
@import 'basscss-responsive-padding';
@import 'basscss-responsive-position';
/*@import 'basscss-responsive-type-scale';*/
@import '_custom-responsive-type-scale';
@import 'basscss-responsive-typography';

/* Variables */
@import '_vars';

/* Sprites */
@import '_sprites';
@import '_sprites-images';

/* Flag object */
@import '_flag';

/* Plugins */
@import '_slick';
@import '_awesomplete';



/* -------------------------------------------------------------------------------- */
/*  =General
/* -------------------------------------------------------------------------------- */

body {
  font-size: 100%;
  font-family: 'proxima-nova', var(--default-font-stack);
  color: var(--gray);
  line-height: 1.5;
  background-color: var(--white-dirt);
}

a {
  color: var(--orange);
  transition: color .25s;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover:not(.btn) { color: var(--gray); }

h1 { font-size: var(--h1); }
h2 { font-size: var(--h2); }
h3 { font-size: var(--h3); }
h4 { font-size: var(--h4); }
h5 { font-size: var(--h5); }
h6 { font-size: var(--h6); }

p:last-child,
ul:last-child {
  margin-bottom: 0;
}
p:first-child {
  margin-top: 0;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  margin: 0;
  border: 0;
  border-bottom: 1px solid #f4f4f4;
}



/* -------------------------------------------------------------------------------- */
/*  =Icons and SVGs
/* -------------------------------------------------------------------------------- */

[class^="icon--"] {
  fill: currentColor;
  transition: fill 0.4s;
}
a:hover [class^="icon--"] {
  fill: currentColor;
}
/*.svg--icon-menu { fill: var(--orange); }*/

[class^="icon--star"] {
  margin-left: 1px;
  margin-right: 1px;
}


/* -------------------------------------------------------------------------------- */
/*  =Buttons
/* -------------------------------------------------------------------------------- */

.btn {
  border-radius: 0;
}
.btn:focus {
  box-shadow: 0 0 0 1px var(--darken-3);
}
.btn-primary,
.btn-outline {
  transition: box-shadow .3s;
}
.btn-outline {
  border-width: 1px;
}
.btn-ghost {
  background-color: rgba(255,255,255,0.5);
}
.btn-fix {
  width: 100%;
  max-width: 180px;
  text-align: center;
}
.btn-input {
  height: 4rem;
}
.btn-rounded {
  border-radius: 50px;
}



/* -------------------------------------------------------------------------------- */
/*  =Helpers
/* -------------------------------------------------------------------------------- */

.clear { clear: both; }
.ttu { text-transform: uppercase; }
.light { font-weight: 300; }
.lh125 { line-height: 1.25; }
.lh2 { line-height: 2; }
.hop-85,
.hop-75 { transition: opacity .25s; }
.hop-85:hover { opacity: .85; }
.hop-75:hover { opacity: .75; }
.op-75 { opacity: .75; }

.max-width-5 { max-width: 80.625rem; }

@media (--breakpoint-sm) {
  .sm-justify-end { justify-content: flex-end; }
}

.orange-light { color: var(--orange-light); }
.bg-white-dirt { background-color: var(--white-dirt); }
.silver-light { color: var(--silver-light); }

.bt-gray { border-top: 1px solid #dfdfdf; }
.bb-gray { border-bottom: 1px solid #dfdfdf; }

.drop-link {
  position: relative;
  padding-right: 12px;
}
.drop-link:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -2px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid var(--black);
}
.drop-link--up:before {
  border-top: 3px solid transparent;
  border-bottom: 4px solid var(--black);
}

.h-divider {
  overflow: hidden;
}
.h-divider span {
  display: inline-block;
  position: relative;
}
.h-divider span:after {
  content: '';
  position: absolute;
  left: 100%;
  margin-left: var(--space-3);
  width: 1200px;
  top: 50%;
  border-bottom: 1px solid #ccc;
}


/* -------------------------------------------------------------------------------- */
/*  =Forms
/* -------------------------------------------------------------------------------- */

label {
  display: block;
}
label:hover {
  cursor: pointer;
}
.input,
.textarea {
  font-family: inherit;
  font-size: inherit;
  display: block;
  width: 100%;
  padding: 1.375rem;
  border: 1px solid #dbdbdb;
  border-radius: 0;
  box-sizing: border-box;
  outline-style: solid;
  outline-width: 0;
  outline-color: #fff;
  transition: border-color .8s;
  -webkit-appearance: none;
}
.input { height: 4rem; }
.textarea { resize: none; height: 12rem; }
.input:focus,
.textarea:focus {
  outline-width: 0;
  border-color: var(--orange);
}

::-webkit-input-placeholder { color: #dbdbdb; }
::-moz-placeholder { color: #dbdbdb; }
:-ms-input-placeholder { color: #dbdbdb; }
:-moz-placeholder { color: #dbdbdb; }


/* Container used for styling the custom select, the buttom class below adds the
 * bg gradient, corners, etc. */
.custom-select {
  position: relative;
  display: block;
}
/* This is the native select, we're making everything but the text invisible so
 * we can see the button styles in the wrapper */
.custom-select select {
  width: 100%;
  margin: 0;
  outline: none;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 0;
  /* Font size must be 16px to prevent iOS page zoom on focus */
  font-size: 16px;
}


/* Custom arrow sits on top of the select - could be an image, SVG, icon font,
 * etc. or the arrow could just baked into the bg image on the select. */
.custom-select::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 1em;
  z-index: 2;
  /* These hacks make the select behind the arrow clickable in some browsers */
  pointer-events: none;
  display: none;
}
.custom-select::after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 7px solid #666;
  margin-top: -3px;
}

/* Firefox <= 34 has a false positive on @supports( -moz-appearance: none )
 * @supports ( mask-type: alpha ) is Firefox 35+
 */
@supports (-webkit-appearance: none) or (appearance: none) or ((-moz-appearance: none) and (mask-type: alpha)) {

  /* Show custom arrow */
  .custom-select::after {
    display: block;
  }

  /* Remove select styling */
  .custom-select select {
    padding-right: 2em; /* Match-01 */
    /* inside @supports so that iOS <= 8 display the native arrow */
    background: none; /* Match-04 */
    /* inside @supports so that Android <= 4.3 display the native arrow */
    border: 2px solid transparent; /* Match-05 */

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-select select:focus {
    border-color: #dbdbdb; /* Match-03 */
  }
}



/* -------------------------------------------------------------------------------- */
/*  =Header
/* -------------------------------------------------------------------------------- */

.site-header {
  background-color: #fff;
}
.page-home .site-header {
  background-color: transparent;
}
.page-home .site-header-inner {
  border-bottom: 1px solid #bdbdbd;
}
.site-header-search {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 12.5%;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.25s, opacity 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.site-header-search.is-visible {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}
.site-header-search-field {
  height: 73px;
  border: 1px solid transparent;
  border-left-color: #dbdbdb;
  border-right-color: #dbdbdb;
  border-bottom: 1px solid var(--orange);
  /*background: transparent url('../../images/search.svg') 20px 50% no-repeat;*/
  padding: var(--space-1) var(--space-2);
  outline-width: 0;
  transition: border .4s;
  box-sizing: border-box;
}
.site-header-search-field:focus {
  outline: 0;
  border-color: var(--orange);
}
@media (--breakpoint-sm) {
  .site-header-search {
    position: relative;
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .site-header-search-field {
    background: transparent url('../../images/search.svg') 20px 50% no-repeat;
    padding: var(--space-1) var(--space-4);
  }
}

a.toggle-menu:hover,
a.toggle-menu.active {
  color: var(--orange);
}
.primary-navigation {
  clear: both;
  min-width: 100%;
}
.primary-navigation > ul {
  @extend .list-reset;
  margin: 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  background-color: var(--white);
  box-shadow: 0px 2px 7px 0 rgba(33, 18, 11, 0.1);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: right;
  width: 100%;
  max-width: 300px;
  padding: 1rem 2rem;
  transform: translateY(-2.5%);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.25s, opacity 0.25s cubic-bezier(0.22, 0.61, 0.36, 1), transform 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.primary-navigation.is-visible > ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 0s;
}
.primary-navigation ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.primary-navigation a {
  padding: 0.625rem 0;
  display: block;
  font-size: var(--h00);
  color: var(--gray);
}
.primary-navigation a:hover,
.primary-navigation .current-menu-item a {
  color: var(--orange);
}


/* -------------------------------------------------------------------------------- */
/*  =Footer
/* -------------------------------------------------------------------------------- */

.site-footer a:hover {
  color: var(--white);
}
.site-footer-widget-title {
  position: relative;
  color: #f9fafa;
}
.site-footer-widget-title:before {
  content: '';
  position: absolute;
  z-index: 1;
  left: -30px;
  top: 6px;
  width: 10px;
  height: 3px;
  background-color: var(--orange);
}
.site-footer-widget a {
  color: var(--silver);
  display: inline-block;
  padding: .25rem 0;
}
@media (--breakpoint-sm) {
  .site-footer-widget-title:before {
    left: -20px;
  }
}
@media (--breakpoint-md) {
  .site-footer-widget-title:before {
    left: -30px;
  }
}



/* -------------------------------------------------------------------------------- */
/*  =Home
/* -------------------------------------------------------------------------------- */

.page-home {
  background-color: #ebeced;
}
.home-hero {
  padding-top: var(--space-4);
  padding-bottom: 12rem;
}
.home-hero-search {
  background-color: #fff;
  padding: var(--space-2) var(--space-3) var(--space-3);
  box-shadow: 0 13px 70px rgba(255, 79, 1, .2);
}
.home-hero-search-field {
  height: 60px;
  border: 0;
  border-bottom: 2px solid var(--orange);
  background: transparent url('../../images/search.svg') 100% 50% no-repeat;
  outline-width: 0;
  transition: border .4s;
  box-sizing: border-box;
}
.home-hero-search-field:focus {
  outline: 0;
}
@media (--breakpoint-sm) {
  .home-hero-search {
    padding: var(--space-3) var(--space-4) var(--space-4);
  }
  .home-hero-search-field {
    height: 90px;
  }
}

.latest-reviews--home {
  transform: translateY(-8rem);
  margin-bottom: -8rem;
}
.most-popular-reviews a span {
  display: inline-block;
  transition: transform .25s;
}
.most-popular-reviews a:hover {
  color: var(--orange);
}
.most-popular-reviews a:hover span {
  transform: translateX(.375rem);
}


/* -------------------------------------------------------------------------------- */
/*  =Car page
/* -------------------------------------------------------------------------------- */

.car-hero {
  width: 100%;
  min-height: 648px;
  background-color: #e6e6e8;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.car-main {
  background-color: var(--white-dirt);
}
.page-add .car-main {
  background-color: var(--white);
}
@media (--breakpoint-sm) {
  .car-main {
    margin-top: var(--space-3);
  }
}
@media (--breakpoint-md) {
  .car-main {
    margin-top: var(--space-4);
  }
}

.car-card {
  width: 100%;
  max-width: 38rem;
  margin: 0 auto;
}

.car-rating-stars svg {
  margin: 0 .1875rem;
}
.car-card .car-rating-stars svg {
  float: left;
  margin: 0 .375rem 0 0;
}
.expert-review-stars svg {
  float: left;
  margin: 0 0 0 .375rem;
}


.car-rating-breakdown {
  border-top: 1px solid #ececec;
}

.car-ratings {
  border-top: 1px solid #ececec;
}
@media (--breakpoint-sm) {
  .car-rating,
  .car-rating-breakdown {
    border-right: 1px solid #ececec;
  }
  .car-ratings {
    border-top: 0;
  }
}
@media (--breakpoint-md) {
  .car-rating,
  .car-rating-breakdown {
    border-right: 0;
  }
}

.car-rate-experience svg {
  margin: 0 .1875rem;
}
.car-rate-experience a {
  transition: background .4s;
}
.car-rate-experience a:hover {
  background-color: #FF1701;
  color: inherit;
}

.car-description {
  position: relative;
}
.car-description.is-collapsed {
  max-height: 130px;
  overflow: hidden;
}
.car-description.is-collapsed:after {
  content: '';
  position: absolute;
  background: linear-gradient(to bottom, rgba(249,250,250,0) 0%,rgba(249,250,250,1) 100%);
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  z-index: 5;
}

.car-reviews-filters {
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}

.car-review {
}
.car-review-date {
  margin-left: 42px;
}
.car-review-content {
  position: relative;
  border-bottom: 1px solid #dfdfdf;
}
.car-review-content:before {
  content: '';
  position: absolute;
  left: 45px;
  top: -7px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid white;
}
.car-review-ratings {
  border-top: 1px solid #dfdfdf;
}
@media (--breakpoint-sm) {
  .car-review-ratings {
    border-top: 0;
    border-left: 1px solid #dfdfdf;
  }
}
.car-review-helpfulness {
  background-color: #f5f5f5;
}


.pagination li {
  display: inline-block;
  margin-right: var(--space-1);
}
.pagination a {
  color: var(--gray);
}
.pagination a:hover {
  color: var(--orange);
}
.pagination a.disabled {
  color: #ccc;
}



.dropdown {
  margin-top: .625rem;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 0;
  background-color: var(--white);
  box-shadow: 0px 2px 7px 0 rgba(33, 18, 11, 0.1);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: right;
  /*width: 100%;*/
  max-width: 300px;
  min-width: 200px;
  padding: 0 .75rem;
  transform: translateY(-2.5%);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.25s, opacity 0.25s cubic-bezier(0.22, 0.61, 0.36, 1), transform 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.dropdown.is-visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 0s;
}
.dropdown a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.dropdown a {
  padding: 0.625rem 0;
  display: block;
  font-size: var(--h5);
  color: var(--gray);
}
.dropdown a:hover,
.dropdown a.active {
  color: var(--orange);
}



/* -------------------------------------------------------------------------------- */
/*  =Tabs
/* -------------------------------------------------------------------------------- */

.tabs {
  margin: 0;
  padding-left: 0;
  text-align: center;
  position: relative;
}
.tabs li {
  display: inline-block;
}
.tabs a {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #f5f5f5;
  text-transform: uppercase;
  color: var(--silver);
}
.tabs a:hover {
  color: inherit;
  background-color: #eee;
}
.tabs .selected {
  background-color: var(--white);
  border-top: 2px solid var(--orange);
  margin-top: -2px;
  color: var(--gray);
}

.vue-tabs.stacked {
  display: flex;
}

.vue-tabs .tabs__link {
  text-decoration: none;
  color: gray;
}

.vue-tabs .nav {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  list-style: none;
}
  .vue-tabs .nav:before, .vue-tabs .nav:after {
    content: " ";
    display: table;
  }
  .vue-tabs .nav:after {
    clear: both;
  }
  .vue-tabs .nav > li {
    position: relative;
    display: block;
  }
    .vue-tabs .nav > li > a {
      position: relative;
      display: block;
    }
      .vue-tabs .nav > li > a:hover, .vue-tabs .nav > li > a:focus {
        text-decoration: none;
        background-color: #eeeeee;
        color: #454545;
      }
    .vue-tabs .nav > li span.title {
      display: flex;
      justify-content: center;
    }
    .vue-tabs .nav > li.disabled > a {
      color: #777777;
    }
      .vue-tabs .nav > li.disabled > a:hover, .vue-tabs .nav > li.disabled > a:focus {
        color: #777777;
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
        border-color: transparent;
      }
  .vue-tabs .nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }
  .vue-tabs .nav > li > a > img {
    max-width: none;
  }

.vue-tabs .nav-tabs {
  /*border-bottom: 1px solid #ddd;*/
}
  .vue-tabs .nav-tabs > li {
    display: inline-block;
    width: 50%;
  }
    .vue-tabs .nav-tabs > li > a {
      /*margin-right: 2px;
      line-height: 1.42857;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0;
      display: block;*/
      display: block;
      padding: 1rem;
      background-color: #f5f5f5;
      text-transform: uppercase;
      color: #868686;
    }
      .vue-tabs .nav-tabs > li > a:hover {
        /*border-color: #eeeeee #eeeeee #ddd;*/
      }
    .vue-tabs .nav-tabs > li.active > a, .vue-tabs .nav-tabs > li.active > a:hover, .vue-tabs .nav-tabs > li.active > a:focus {
/*      color: #555555;
      background-color: #fff;
      border: 1px solid #ddd;
      border-bottom-color: transparent;
      cursor: default;*/
      background-color: var(--white);
      border-top: 2px solid var(--orange);
      margin-top: -2px;
      color: var(--gray);
    }

.vue-tabs .nav-stacked > li {
  float: none;
}
  .vue-tabs .nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0;
  }

.vue-tabs .tab-content {
  font-size: var(--h5);
  padding: var(--space-3);
}
.vue-tabs .tab-content > .tab-pane {
  display: none;
}
@media (--breakpoint-sm) {
  .vue-tabs .tab-container {
    padding: var(--space-2);
  }
}

.vue-tabs .tab-content > .active {
  display: block;
}

.vue-tabs section[aria-hidden="true"] {
  display: none;
}



/* -------------------------------------------------------------------------------- */
/*  =Brand/Category
/* -------------------------------------------------------------------------------- */

.page-brand,
.page-all-cars {
  background-color: #ebeced;
}

.car-list--up {
  transform: translateY(-8rem);
  margin-bottom: -8rem;
}
.car-list-filters-wrap {
  border-bottom: 1px solid #dfdfdf;
}
.car-list-filters a {
  color: var(--gray);
  display: inline-block;
  padding: 0 var(--space-2) var(--space-2);
  margin: 0 .25rem;
  border-bottom: 2px solid transparent;
  margin-bottom: -1px;
  transition: border-color .25s;
}
@media (--breakpoint-lg) {
  .car-list-filters a {
    margin: 0 var(--space-1);
  }
}
.car-list-filters a:last-child {
  margin-right: 0;
}
.car-list-filters a:hover,
.car-list-filters a.active {
  border-bottom-color: var(--orange);
}



/* -------------------------------------------------------------------------------- */
/*  =Page/Content
/* -------------------------------------------------------------------------------- */

.entry-content {
  line-height: 1.8125;
}
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  margin-top: var(--space-3);
  margin-bottom: var(--space-2);
}
.entry-content h1 { font-size: var(--h2); }
.entry-content h2 { font-size: var(--h3); }
.entry-content h3 { font-size: var(--h4); }
.entry-content h4 { font-size: var(--h0); }
.entry-content h5 { font-size: var(--h0); }
.entry-content h6 { font-size: var(--h0); }
@media (--breakpoint-sm) {
  .entry-content h1 { font-size: var(--h1); }
  .entry-content h2 { font-size: var(--h2); }
  .entry-content h3 { font-size: var(--h3); }
  .entry-content h4 { font-size: var(--h4); }
  .entry-content h5 { font-size: var(--h5); }
  .entry-content h6 { font-size: var(--h5); }
}

.entry-content > :first-child {
  margin-top: 0;
}
.entry-content > :last-child {
  margin-bottom: 0;
}

.entry-content a:not(.btn) {
  text-decoration: underline;
  color: var(--orange);
}
.entry-content a:hover:not(.btn) {
  /*color: var(--gray);*/
}

.entry-content blockquote {
  border-left: 3px solid var(--silver-light);
  padding-left: 1rem;
  margin-left: 1rem;
  font-style: italic;
  font-size: var(--h4);
}

.entry-content p,
.entry-content ul,
.entry-content ol {
  font-size: var(--h5);
  margin-bottom: var(--space-2);
}
@media (--breakpoint-sm) {
  .entry-content p,
  .entry-content ul,
  .entry-content ol {
    font-size: var(--h00);
  }
}
.entry-content ul,
.entry-content ol {
  padding-left: 0;
  list-style-position: inside;
}
.entry-content ul ul,
.entry-content ul ol,
.entry-content ol ul,
.entry-content ol ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: var(--space-2);
}

.entry-content figcaption {
  color: var(--silver);
}
.entry-content .align_left {text-align: left; }
.entry-content .align_right {text-align: right; }
.entry-content .align_center {text-align: center; }



/* -------------------------------------------------------------------------------- */
/*  =Vue
/* -------------------------------------------------------------------------------- */

.ajax-loader {
  position: fixed;
  z-index: 99;
  right: 32px;
  bottom: 24px;
  width: 64px;
  display: none;
}
.ajax-loader.is-visible {
  display: block;
}

.vue-star-rating {
  justify-content: center;
}



/* Transitions */
.fade-enter-active {
  transition: opacity .35s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}
.list-item {
  transition: opacity .35s
}
.list-enter-active, .list-leave-active {
  transition: all .35s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(15px);
}

/*
.grid-item {
  transition: opacity .35s
}
.grid-enter-active, .grid-leave-active {
  transition: all .35s;
}
.grid-enter, .grid-leave-active below version 2.1.8 {
  opacity: 0;
  transform: translateY(15px);
}
.grid-leave-to {
  display: none;
}
*/

.grid-item {
  transition: all .35s;
}
.grid-enter, .grid-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.grid-leave-active {
  position: absolute;
}
