/* ====== FLAG OBJECT ====== */
/* URL: http://csswizardry.com/2013/05/the-flag-object/ */
@media (--breakpoint-sm) {
  .flag {
    display: table;
    width: auto;
  }

  .flag__image, .flag__body {
    display: table-cell;
    vertical-align: middle;
  }
  .flag--top .flag__image, .flag--top .flag__body {
    vertical-align: top;
  }
  .flag--bottom .flag__image, .flag--bottom .flag__body {
    vertical-align: bottom;
  }

  .flag__image {
    padding-right: 2rem;
  }
  .flag__image > img {
    display: block;
    max-width: none;
  }
  .flag--rev .flag__image {
    padding-right: 0;
    padding-left: 10px;
  }

  .flag__body {
    width: 100%;
  }
}
