.icon--facebook-square {
	width: 24px;
	height: 24px;
}

.icon--linkedin {
	width: 24px;
	height: 24px;
}

.icon--menu {
	width: 26px;
	height: 19px;
}

.icon--pagination-arrow-left {
	width: 7px;
	height: 12px;
}

.icon--pagination-arrow-right {
	width: 7px;
	height: 12px;
}

.icon--pinterest-p {
	width: 24px;
	height: 24px;
}

.icon--search {
	width: 22px;
	height: 23px;
}

.icon--slider-arrow-left {
	width: 22px;
	height: 16px;
}

.icon--slider-arrow-right {
	width: 22px;
	height: 16px;
}

.icon--star {
	width: 20px;
	height: 18px;
}

.icon--star-13 {
	width: 13px;
	height: 12px;
}

.icon--star-13-half {
	width: 13px;
	height: 12px;
}

.icon--star-13-outline {
	width: 13px;
	height: 12px;
}

.icon--star-24-outline {
	width: 22px;
	height: 22px;
}

.icon--star-half {
	width: 20px;
	height: 18px;
}

.icon--star-outline {
	width: 20px;
	height: 18px;
}

.icon--text-document {
	width: 20px;
	height: 20px;
}

.icon--thumbs-up {
	width: 15px;
	height: 15px;
}

.icon--twitter {
	width: 24px;
	height: 24px;
}

